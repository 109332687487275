.blog {
  display: flex;
  justify-content: space-around;
  margin-top: 100px;
}

.blog-left {
  width: calc(100% - 30vw);
}

.blog-right {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background-color: var(--clr-lighterDark);
  border-radius: 10px;
  width: 28vw;
  height: 80vh;
  margin-left: 5vw;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 0 0 10px black;
}

.blog-right::-webkit-scrollbar {
  width: 0.5em;
}

.blog-right::-webkit-scrollbar-track {
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.blog-right::-webkit-scrollbar-thumb {
  background-color: var(--clr-lighterDark);
  border-radius: 10px;
  outline: 1px solid slategrey;
}

.blog-right-title {
  /* margin-left: 1rem; */
  text-align: center;
  margin-bottom: 0%;
}

.toggle-buttons {
  display: none;
  justify-content: center;
  margin-bottom: 1rem;
}

.blog-button {
  text-decoration: none;
  border: var(--clr-accent) 2px solid;
  border-radius: 7px;
  padding: 1rem 10vw;
  margin: 2vw;
  color: var(--clr-darkerLight);
  transition: 0.3s;
  font-weight: var(--fw-bold);
  align-self: center;
  white-space: nowrap;
}

.blog-button:hover {
  background-color: rgba(109, 152, 134, 0.1);
  color: var(--clr-accent);
  cursor: pointer;
}

.section-hide {
  display: flex !important;
  flex-direction: column;
}

/* Rearrange blog structure at 900px width */
@media screen and (max-width: 900px) {
  .blog {
    flex-direction: column;
  }

  .section-hide {
    display: none !important;
  }

  .toggle-buttons {
    display: flex;
    /* background-color: var(); */
  }

  .blog-left {
    width: 100%;
  }

  .blog-right {
    position: relative;
    display: none;
    flex-direction: column;
    overflow-y: auto;
    height: auto;
    width: 100%;
    margin: 0;
    top: 0;
  }

  .blog-right::-webkit-scrollbar {
    display: none;
  }

  .blog-right-title {
    font-size: var(--fs-h2);
  }
}
