.work-item {
  display: flex;
  flex-direction: row;
  margin: 0rem 5rem 5rem;
  padding: 0.5rem;
  height: auto;
  min-height: 400px;
  background-color: var(--clr-lighterDark);
  box-shadow: inset 3px 3px 3px var(--clr-accent),
    inset -3px -3px 3px var(--clr-accent), 3px 3px 15px var(--clr-dark);
  border-radius: 5px;
}

.work-item-left {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 400px;
  margin-left: 0.5rem;
  padding-right: 4rem;
  justify-content: space-between;
}

.work-item-title {
  margin: 0;
  /* overflow-wrap: break-word; */
  flex-wrap: wrap;
  font-weight: 500;
}

.work-item-left a {
  display: flex;
  text-decoration: none;
  color: inherit;
  align-items: center;
}

.work-item-left-description p {
  font-weight: 300;
  font-size: 1.1rem;
}

.work-item-left .git-logo {
  justify-self: flex-end;
}

.work-item-left-plugs {
  display: flex;
  flex-direction: row;
}

.work-item-left .git-logo img {
  width: 45px;
  height: auto;
  margin-right: 1rem;
}

.external-logo img {
  width: 60px;
  height: auto;
}

.work-item-mid {
  display: block;
  flex-grow: 2;
  width: 500px;
  transition: var(--transition);
}

.work-item-mid:hover {
  transform: scale(1.2);
  height: auto;
  opacity: 0.98;
}

.portfolio {
  background-image: url(../resources/portfolio-pic.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  opacity: 0.5;
}

.watchit {
  background-image: url(../resources/WachItConfigurationPic.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  opacity: 0.5;
}

.tablespoon-treats {
  background-image: url(../resources/PeaceOfCake.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  opacity: 0.5;
}

.work-item-right {
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
  text-align: end;
  margin-left: 2rem;
  width: 100px;
}

.work-item-right ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
  align-items: stretch;
  justify-content: space-around;
}

.work-item-right ul li {
  background-color: var(--clr-accent);
  padding: 2px;
  border-radius: 5px;
  width: auto;
}

@media screen and (max-width: 1200px) {
  .work-item {
    margin: 0rem 3rem 3rem;
  }

  .work-item-mid:hover {
    transform: scale(1.5);
    transition: var(--transition);
  }
}

@media screen and (max-width: 900px) {
  .work-item-mid {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .work-item {
    margin: 0 1rem 3rem;
  }

  .work-item-left {
    padding-right: 0%;
    margin: 0;
    width: auto;
  }

  .work-item-right {
    text-align: center;
    width: auto;
  }
}

@media screen and (max-width: 500px) {
  .work-item {
    flex-direction: column;
  }

  .work-item-left {
    width: auto;
  }

  .work-item-right {
    text-align: center;
    width: auto;
    margin: 0;
  }

  .work-item-right ul {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    flex-grow: 1;
    flex-wrap: wrap;
    margin: 0.5rem 0 0;
    width: auto;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 350px) {
  .work-item {
    margin-left: 0;
    margin-right: 0;
  }
  .work-item-title {
    font-size: 10vw;
  }

  .work-item-left-header span {
    font-size: 8vw;
  }

  .work-item-left-description p {
    font-size: 12px !important;
  }

  .work-item-left-plugs a {
    min-width: 0;
  }
}
