.picture-post {
  display: flex;
  flex-direction: column;
  margin: 2rem 1rem;
  border-radius: 0;
  box-shadow: 0px 0px 10px black;
}

.picture-post img {
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  outline: 1px black solid;
}

.picture-title-container {
  display: flex;
  background-color: var(--clr-accent);
  width: 100%;
  align-self: center;
  justify-content: space-between;
  padding-left: 0.5rem;
  /* border: px black solid; */
  outline: 1px black solid;
}

.picture-title-empty {
  content: "";
  align-self: center;
  width: 100px;
  height: 10px;
  background-color: var(--clr-accent2);
}
