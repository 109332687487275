@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,700");

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --ff-primary: "Poppins", serif;
  --ff-secondary: "Recursive", sans-serif;

  --fw-reg: 300;
  --fw-bold: 900;

  --clr-light: #f0fff0;
  --clr-darkerLight: #e0ddd8;
  --clr-dark: rgb(33, 33, 33);
  --clr-lighterDark: rgba(196, 196, 196, 0.1);
  --clr-accent: #115234;
  --clr-accent-faded: #115234b2;
  --clr-navbar: rgb(31, 34, 31);
  --clr-accent2: #c84b31;

  --fs-h1: 3rem;
  --fs-h2: 2.25rem;
  --fs-h3: 1.25rem;
  --fs-body: 1rem;
  --bs: 0.25em 0.25em 0.75em rgba(0, 0, 0, 0.25),
    0.125em 0.125em 0.25em rgba(0, 0, 0, 0.15);

  --transition: all 0.25s cubic-bezier(0.65, 0.05, 0.36, 1);
}

h1 {
  font-size: var(--fs-h1);
  font-weight: var(--fw-bold);
}

h2 {
  font-size: var(--fs-h2);
  color: var(--clr-darkerLight);
  font-weight: var(--fw-reg);
}

h3 {
  font-size: var(--fs-h3);
  color: var(--clr-darkerLight);
  font-weight: var(--fw-reg);
}

p {
  font-size: var(--fs-body);
}

span {
  color: var(--clr-accent2);
}

html {
  scroll-behavior: smooth;
}

b {
  color: var(--clr-accent2);
}

body {
  padding: 0;
  margin: 0;
  background-color: #212121;
  font-family: var(--ff-primary);
  color: var(--clr-light);
  min-height: 100vh;
  width: auto;
  /* display: flex;
  flex-direction: column; */
  -webkit-font-smoothing: antialiased;
  line-height: 1.3;
}

body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--clr-lighterDark);
  border-radius: 10px;
  outline: 1px solid slategrey;
}

.hide {
  display: none !important;
}

.content {
  margin: 0 2rem;
}

@media screen and (max-width: 768px) {
  .content {
    margin: 0 1rem;
  }
}

/* section {a
  margin: 5rem;
  padding-bottom: 14vh;
} */

/* Nav Section */
.navbar-header {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  position: fixed;
  background-color: var(--clr-dark);
  top: 0px;
  z-index: 999;
  padding: 0px 0px;
  width: 100%;
  height: 80px;
  filter: none;
  transition: var(--transition);
  pointer-events: auto;
  user-select: auto;
}

.scroll-down {
  transform: translate3d(0, -150%, 0);
}

/* .scroll-up {
  transform: translate3d(0, 100%, 0);
} */

.main-nav {
  display: flex;
  -webkit-box-pack: justify;
  -webkit-box-align: center;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  counter-reset: item 0;
  border-bottom: 2px var(--clr-accent) solid;
  box-shadow: 0px 10px 10px var(--clr-dark);
  background-color: var(--clr-navbar);
}

.nav-links-container {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.nav-links {
  display: flex;
  margin-right: 2rem;
}

.nav-links a {
  padding: 1rem;
}

.main-nav ul {
  list-style: none;
}

.main-nav a {
  color: inherit;
  text-decoration: none;
  /* padding: 1rem; */
}

.main-nav a:hover {
  color: var(--clr-accent);
  transition: var(--transition);
}

.nav-logo img {
  position: relative;
  text-decoration: none;
  top: 5px;
  width: 90px;
  margin-left: 1rem;
  height: auto;
  justify-self: center;
}

.burger {
  display: none;
  margin-right: 2rem;
}

.burger div {
  width: 25px;
  height: 2px;
  background-color: var(--clr-darkerLight);
  margin: 5px;
  opacity: 0.9;
}

.nav-active {
  transform: translateX(0%) !important;
}

@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@media screen and (max-width: 768px) {
  body {
    overflow-x: hidden;
  }

  .nav-links {
    position: absolute;
    right: 0px;
    height: 92vh;
    top: 80px;
    padding: 0%;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: var(--clr-dark);
    width: 30%;
    transform: translateX(100%);
    transition: transform 0.25s ease-in;
    border-left: 2px var(--clr-accent) solid;
    padding-bottom: 15vh;
    /* opacity: 0.99; */
  }

  .nav-links a {
    padding: 0;
  }

  .nav-links li {
    opacity: 0;
  }

  .nav-links li:hover {
    font-weight: var(--fw-bold);
  }

  .nav-button {
    padding: 0.75rem 0.5rem !important;
  }

  .burger {
    display: block;
    cursor: pointer;
  }
}

/* Home Section */
.home {
  display: grid;
  position: relative;
  grid-template-columns: min-content 500px;
  grid-template-rows: auto;
  grid-template-areas:
    "img title"
    "img subtitle"
    "img action";
  width: 100%;
  min-height: 100vh;
  column-gap: 2rem;
  /* background-color: rgb(36, 36, 36); */
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
}

.home-philip-img {
  grid-area: img;
  min-width: 400px;
  position: relative;
  z-index: 2;
  box-shadow: 10px 5px 7px var(--clr-dark);
}

.home-philip-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* box-shadow: 10px 5px 7px var(--clr-dark); */
  z-index: 2;
}

.home-title {
  position: relative;
  grid-column: 1 / 3;
  grid-row: 1;
  left: 0;
  width: calc(100% + 3em);
  padding: 0.75rem 2rem 0.75rem 0;
  text-align: right;
  background-color: var(--clr-accent);
}

.home-subtitle {
  text-align: center;
}

.resume {
  text-decoration: none;
  border: var(--clr-accent) 2px solid;
  border-radius: 7px;
  padding: 1rem 2rem;
  margin-top: 50px;
  color: var(--clr-darkerLight);
  transition: 0.3s;
  font-weight: var(--fw-bold);
  align-self: center;
}

.resume:hover {
  background-color: rgba(109, 152, 134, 0.1);
}
@media screen and (max-width: 1200px) {
  .home {
    grid-template-columns: min-content 350px;
  }

  .home-title {
    font-size: 2.25rem;
    padding-right: 1rem;
    margin-top: 4rem;
  }

  .home-philip-img {
    min-width: 350px;
    margin-right: 1rem;
  }
}

@media screen and (max-width: 900px) {
  .home {
    display: flex;
    flex-direction: column;
    /* margin: 0 -1rem; */
    padding-top: 150px;
  }

  .home-philip-img {
    min-width: 0;
    max-width: 400px;
    flex-shrink: 1;
    margin-bottom: 2rem;
    margin-right: 0;
  }

  .home-philip-img img {
    width: 100%;
    height: auto;
  }

  .home-title {
    left: auto;
    width: auto;
    padding: 1.5rem 0.5rem;
    margin-top: 0;
    text-align: center;
  }

  .home-subtitle {
    max-width: 350px;
  }
}

@media screen and (max-width: 480px) {
  .philip-img img {
    min-width: 200px;
  }
}

/* About section */
.about {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0 auto;
  max-width: 1000px;
}

.about-title::after {
  content: "";
  display: block;
  position: relative;
  width: 7em;
  height: 2px;
  top: -30px;
  margin-left: 18rem;
  opacity: 0.5;
  background: var(--clr-accent);
}

.about-content-1 {
  display: flex;
}

.about-description {
  flex-grow: 1;
  /* width: 75%; */
  height: 100%;
  align-self: center;
  margin-right: 5rem;
}

.about-description-tech {
  position: relative;
  bottom: 100%;
}

.about-image-container {
  display: flex;
  flex-grow: 1;
  justify-self: center;
  align-items: center;
}

.philip-utah-img {
  max-width: 65%;
  min-width: 100px;
  height: auto;
  transform: translateY(5%);
  box-shadow: 6px -3px 10px var(--clr-dark);
  align-items: center;
  justify-self: center;
  border-radius: 8px;
}

.philip-utah-img-outline {
  display: flex;
  position: relative;
  justify-content: center;
}

.philip-utah-img-outline:before {
  position: absolute;
  top: -5%;
  left: 25%;
  width: 65%;
  min-width: 100px;
  height: 100%;
  border: 4px solid rgba(177, 72, 27, 1);
  border-radius: 8px;
  content: "";
  box-shadow: 4px -4px 20px var(--clr-accent);
}

.skills {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  background-color: var(--clr-lighterDark);
  padding: 1rem;
  border-radius: 0.5rem;
  flex-wrap: wrap;
}

.skills-col {
  display: flex;
  flex-direction: column;
  list-style: none !important;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
}

.skill {
  position: relative;
  width: 150px;
  height: 50px;
  text-align: center;
  background-color: #c84b31;
  border: 2px var(--clr-dark) solid;
  font-size: var(--fs-h3);
  font-weight: var(--fw-bold);
  box-shadow: 10px -4px 4px var(--clr-dark);
  color: var(--clr-dark);
}

@media screen and (max-width: 900px) {
  .about-content-1 {
    display: flex;
    flex-direction: column-reverse;
  }

  .about-description {
    padding: 0;
    margin: 0;
    margin-bottom: 3rem;
  }

  .about-image-container {
    margin-top: 2rem;
    margin-bottom: 5rem;
  }
}

/* skill adjustments */
.skill-1 {
  /* left: 30%; */
  z-index: 11;
}

.skill-2 {
  /* left: 20%; */
  z-index: 10;
}

.skill-3 {
  /* left: 10%; */
  z-index: 9;
}

.skill-4 {
  z-index: 8;
}

.skill-5 {
  /* left: 2vw; */
  z-index: 7;
}

.skill-6 {
  z-index: 6;
}

.skill-7 {
  /* left: -3vw; */
  z-index: 5;
}

.skill-8 {
  z-index: 4;
}

.skill-9 {
  z-index: 3;
}

.skill-10 {
  z-index: 2;
}
/* end skill adjustments */

.skills-item {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  background-color: #346751;
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  margin: 0.5rem;
}
.skills-item hr {
  width: 150px;
}

@media screen and (max-width: 768px) {
  .about {
    margin: 0;
  }

  .about-title::after {
    margin: 0;
    top: 0%;
    width: auto;
  }
}

@media screen and (max-width: 480px) {
  .about-title {
    text-align: center;
  }
}

/* work */
.work {
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
  margin: 5rem auto 0;
  /* max-width: 1100px; */
  padding: 0;
}

.work-title {
  margin-left: 5rem;
}

.work hr {
  background-color: var(--clr-accent);
  height: 1px;
  border: none;
  width: 75%;
}

@media screen and (max-width: 1200px) {
  .work-title {
    margin-left: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .work-title {
    margin-left: 1rem;
  }
}

@media screen and (max-width: 350px) {
  .work-title {
    margin-left: 0;
  }
}

/* Contact */
.contact {
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
  max-width: 600px;
  margin: 5rem auto 100px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.email-link {
  text-decoration: none;
  border: var(--clr-accent) 2px solid;
  border-radius: 7px;
  padding: 1rem;
  margin-top: 50px;
  color: var(--clr-darkerLight);
  transition: 0.3s;
}
.email-link:hover {
  background-color: rgba(109, 152, 134, 0.1);
}

/* Footer */
.footer {
  margin: 2rem auto 0;
  /* padding-bottom: 1rem; */
  max-width: 600px;
}

.plugs {
  display: flex;
  justify-content: space-around;
  margin-left: 1rem;
  margin-right: 1rem;
}

.creds {
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0;
}

.creds span {
  color: var(--clr-accent);
}
